/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="carrForm">
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field" class="w-100">
                      {{cvCarrFieldLabel}}</label>
                    <ValidationProvider
                          name="Career Field Name"
                          rules="required"
                          v-slot="{ errors }">
                      <input
                        v-model="vmCareerFormData.carr_field"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                      <label
                      for="validationcarr_field" class="w-100">
                      {{cvCarrImageLabel}}</label>
                      <div class="profile-img-edit border" style="height: 150px; width: 150px; border-radius: 50%; ">
                        <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden; border-radius: 50%; " />
                        <i v-else class="fas fa-image d-flex justify-content-center align-items-center rounded-circle" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" title="Upload Image" style="cursor: pointer;"></i>
                          <input class="file-upload" type="file" accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Profile Pic')" required />
                        </label>
                      </div>
                      <br/>
                    </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcarr_desc" class>
                      {{ cvCarrDescLabel }}
                    </label>
                    <ValidationProvider
                      name="Career Description"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_desc" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcarr_hiring_org">
                      {{ cvCarrHiringOrgLabel }}</label>
                    <ValidationProvider
                    name="Career Hiring organization"
                    rules="required"
                    v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_hiring_org" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcarr_facts_stories">
                      {{ cvCarrFactsStoriesLabel }}</label>
                    <ValidationProvider
                    name="Career Facts Stories"
                    rules="required"
                    v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_facts_stories" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrCategoryLabel}}</label>
                    <select type="text" v-model="vmCareerFormData.carr_category" class="form-control">
                      <option value="">Please select one</option>
                      <option v-for="item in cvcareerCategoryOptions" :key="item.carr_cat_name">{{ item.carr_cat_name }}</option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcarr_role_responsibility">
                      {{ cvCarrRoleResponsibilityLabel }}</label>
                    <ValidationProvider
                    name="Career Role Responsibility"
                    rules="required"
                    v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_role_responsibility" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcarr_journey">
                      {{ cvCarrJourneyLabel }}</label>
                    <ValidationProvider
                    name="Career Journey"
                    rules="required"
                    v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_journey" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcarr_myth_buster">
                      {{ cvCarrMythBusterLabel }}</label>
                    <ValidationProvider
                    name="Career Myth Buster"
                    rules="required"
                    v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_myth_buster" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-12">
                    <label for="validationcarr_myth_buster">
                      {{ cvCarrCoverImageLabel }}</label>
                      <div class="form-group row align-items-center">
                        <div class="col-md-12">
                          <div class="profile-img-edit border" style="height: 250px; width: 100%;">
                            <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                            <i v-else class="fas fa-image d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                            <label class="p-image">
                              <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                              <input class="file-upload" type="file" accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" required />
                            </label>
                          </div>
                        </div>
                        <span class="text-danger" ref="coverPicError"></span>
                      </div>
                  </div>
                  <div class="form-group row spinner_add pl-3">
                    <button
                      v-if="cvLoadingStatus"
                      type="button"
                      class="btn btn-primary float-right"
                      :disabled="cvLoadingStatus">
                      <b-spinner label="Spinning">
                      </b-spinner>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="careerAdd()">
                      {{ cvSubmitBtn }}
                    </button>
                  </div>
              </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" required />
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
  label{
    text-transform: capitalize;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { Careers } from "../../../../FackApi/api/career"
import ApiResponse from "../../../../Utils/apiResponse.js"
import CropImage from "../../../../components/cropImage.vue"
import { Images } from "../../../../FackApi/api/image.js"
import CareerUtility from "../../../Admin/Career/CareerUtility"
import { CareerCategorys } from "../../../../FackApi/api/careerCategory.js"
import { ValidationProvider } from "vee-validate"
import { socialvue } from "../../../../config/pluginInit"
import { VueEditor } from "vue2-editor"
import userPermission from "../../../../Utils/user_permission.js"

export default {
  name: "CareerAdd",
  components: {
    CropImage,
    ValidationProvider,
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "career_add",
      cvSelectBoxText: "Select ",
      cvCardTitle: "Add career",
      cvSubmitBtn: "Add",
      cvCarrFieldLabel: "career name",
      cvCarrImageLabel: "career profile Image",
      cvCarrCoverImageLabel: "career cover Image",
      cvCarrDescLabel: "career description",
      cvCarrHiringOrgLabel: "career hiring org",
      cvCarrFactsStoriesLabel: "career facts stories",
      cvCarrCategoryLabel: "career category",
      cvCarrRoleResponsibilityLabel: "career role responsibility",
      cvCarrJourneyLabel: "career journey",
      cvCarrMythBusterLabel: "career myth buster",
      cvCareerFieldOptions: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Career",
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      vmProfilePic: "",
      vmCarProPicImage: Object.assign({}, this.initImageData()),
      vmCarCoverPicImage: Object.assign({}, this.initImageData()),
      vmCareerFormData: Object.assign({}, this.initFormData()),
      cvCarrFieldHashLabel: "career field hash",
      vmCareerDesc: null,
      cvcareerCategoryOptions: "",
      vmCoverPic: "",
      vmCareerType: null
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.cvCareerFieldOptions = await this.loadRequiredData()
    this.careerCategoryList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        carr_field: "",
        carr_desc: "",
        carr_hiring_org: "",
        carr_facts_stories: "",
        carr_category: "",
        carr_role_responsibility: "",
        carr_journey: "",
        carr_myth_buster: ""
      }
    },
    loadRequiredData () {
      return CareerUtility.careerFieldList(this)
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerFormData) {
          if (!this.vmCareerFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error(
          "Exception occurred at validate() and Exception:",
          err.message
        )
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * careerCategoryList
     */
    async careerCategoryList () {
      try {
        let careerCategoryListResp = await CareerCategorys.careerCategoryList(this)
        this.cvcareerCategoryOptions = careerCategoryListResp.resp_data.data

        if (careerCategoryListResp.resp_status) {
          this.careerCategoryObjList = careerCategoryListResp.resp_data.data
          this.totalRows = careerCategoryListResp.resp_data.count
        }
        else {
          this.toastMsg = careerCategoryListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerCategoryList() and Exception:", err.message)
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (carId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "CARR",
          "module_id": carId,
          "image": []
        }

        if (this.vmCarProPicImage.image) {
          imageObj.image.push(this.vmCarProPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        if (this.vmCarCoverPicImage.image) {
          imageObj.image.push(this.vmCarCoverPicImage)
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        this.vmCarProPicImage = Object.assign({}, this.initImageData())
        this.vmCrgCoverPicImage = Object.assign({}, this.initOrgData())
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * careerAdd
     */
    async careerAdd () {
      let valid = await this.$refs.carrForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        let careerAddResp = await Careers.careerAdd(
          this,
          this.vmCareerFormData
        )
        await ApiResponse.responseMessageDisplay(this, careerAddResp)
        if (careerAddResp && !careerAddResp.resp_status) {
          return false
        }
        if (careerAddResp.resp_data && careerAddResp.resp_data.carr_id) {
          this.cvLoadingStatus = false
          await this.imageAdd(careerAddResp.resp_data.carr_id)
          await this.$router.push("./careersList")
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          // this.$emit("emitCloseCareerAddModal", this.vmCareerFormData)
        }
        this.vmCareerFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error(
          "Exception occurred at careerAdd() and Exception:",
          err.message
        )
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmCarProPicImage = Object.assign({}, this.initImageData())
        this.vmCarProPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmCarProPicImage.image_type = "profile_pic"
      }
      else {
        this.vmCarCoverPicImage = Object.assign({}, this.initImageData())
        this.vmCarCoverPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmCarCoverPicImage.image_type = "cover_pic"
      }
    }
  }
}
</script>
